import React, { useState } from "react"
import * as styles from './layout.module.scss';
import '../../../style/all.scss';
import { Link } from "gatsby"

import LogoSVG from "../../../icons/logoTop.svg";
import LogoFooterSVG from "../../../icons/logoFooter.svg";

import ChevronRightSVG from "../../../icons/fontawesome/light/chevron-right.svg";

import BarsSVG from "../../../icons/fontawesome/solid/bars.svg";
import TimesSVG from "../../../icons/fontawesome/solid/times.svg";

import "@fontsource/roboto-condensed/300.css";
import "@fontsource/roboto-condensed/400.css";
import "@fontsource/roboto-condensed/700.css";
import "@fontsource/roboto/700.css";

const Layout = ( { children } ) => {

  const [ showMobileOverlay, setShowMobileOverlay ] = useState(false);

  return <>

    { showMobileOverlay && <div className={styles.mobileOverlay + " d-flex justify-content-center align-items-center"}>

      <TimesSVG onClick={ () => setShowMobileOverlay(false) }
                className={styles.mobileOverlayClose + " svgFill"} />

      <ul className={"d-flex flex-column"}>
        <Link to={"/"}>Home</Link>
        <Link to={"/orte"}>Orte</Link>
        <Link to={"/kategorien"}>Kategorien</Link>
        <Link to={"/regionen"}>Region</Link>
        <Link to={"/karte"}>Karte</Link>
        <Link to={"/kontakt"}>Kontakt</Link>
      </ul>
    </div> }

    <div className={styles.headerWrapper}>

      <div className="container">
        <div className="row">
          <div className="col-12">

            <div className={styles.header}>

              <Link to={"/"} className={"text-decoration-none"} >

                <div className="d-flex align-items-end text-decoration-none" >
                  <LogoSVG className={styles.logo} />

                  <p className={styles.wordmark + " d-none d-lg-block"}>
                    interRegional.info
                  </p>
                </div>

              </Link>

              <nav className={"d-flex"}>
                <Link to={"/"}>Home</Link>
                <Link to={"/orte"}>Orte</Link>
                <Link to={"/kategorien"}>Kategorien</Link>
                <Link to={"/regionen"}>Regionen</Link>
                <Link to={"/karte"}>Karte</Link>
                <Link to={"/kontakt"}>Kontakt</Link>
              </nav>


              <BarsSVG className={styles.bars}
                  onClick={
                    () => setShowMobileOverlay(s => !s)
                  }
              />


            </div>

          </div>
        </div>
      </div>

    </div>

    <div className={styles.layoutWrapper}>

        <div className={styles.children}>
              { children }
        </div>

        <div className={styles.footer}>
          <div className="container">
            <div className="row">

              <div className="col-lg-4">
                <p className={"opacity-50"}>&copy; 2022 interregional.info</p>


                <ul>
                  <li >
                    <Link to={"/"} className={"d-flex d-lg-none align-items-center text-white text-decoration-none"}>
                      <ChevronRightSVG className={"svgFill me-1"} />
                      Startseite
                    </Link>

                    <Link to={"/orte"} className={"d-flex d-lg-none align-items-center text-white text-decoration-none"}>
                      <ChevronRightSVG className={"svgFill me-1"} />
                      Orte
                    </Link>

                    <Link to={"/kategorien"} className={"d-flex d-lg-none align-items-center text-white text-decoration-none"}>
                      <ChevronRightSVG className={"svgFill me-1"} />
                      Kategorien
                    </Link>

                    <Link to={"/regionen"} className={"d-flex d-lg-none align-items-center text-white text-decoration-none"}>
                      <ChevronRightSVG className={"svgFill me-1"} />
                      Regionen
                    </Link>

                    <Link to={"/karte"} className={"d-flex d-lg-none align-items-center text-white text-decoration-none"}>
                      <ChevronRightSVG className={"svgFill me-1"} />
                      Karte
                    </Link>

                    <Link to={"/kontakt"} className={"d-flex d-lg-none align-items-center text-white text-decoration-none"}>
                      <ChevronRightSVG className={"svgFill me-1"} />
                      Kontakt
                    </Link>


                    <Link to={"/impressum"} className={"d-flex align-items-center text-white text-decoration-none"}>
                      <ChevronRightSVG className={"svgFill me-1"} />
                      Impressum
                    </Link>

                    <Link to={"/datenschutz"} className={"d-flex align-items-center text-white text-decoration-none"}>
                      <ChevronRightSVG className={"svgFill me-1"} />
                      Datenschutz
                    </Link>

                  </li>
                </ul>
              </div>

              <div className="col-lg-4 text-center text-lg-start  d-none d-lg-block">
                <Link to={"/"} className={"mx-auto d-block p-relative"}>
                  <LogoFooterSVG className={styles.logoFooter + " mx-auto"} />
                </Link>
              </div>

              <div className="col-lg-4 text-end d-none d-lg-block">

                <h3>Navigation</h3>
                <ul>
                  <li >
                    <Link to={"/"} className={"d-flex justify-content-end align-items-center text-white text-decoration-none"}>
                      Startseite
                      <ChevronRightSVG className={"svgFill ms-1"} />
                    </Link>
                  </li>

                  <li >
                    <Link to={"/orte"} className={"d-flex  justify-content-end align-items-center text-white text-decoration-none mt-2"}>
                      Orte
                      <ChevronRightSVG className={"svgFill ms-1"} />
                    </Link>
                  </li>

                  <li >
                    <Link to={"/kategorien"} className={"d-flex  justify-content-end align-items-center text-white text-decoration-none mt-2"}>
                      Kategorien
                      <ChevronRightSVG className={"svgFill ms-1"} />
                    </Link>
                  </li>


                  <li >
                    <Link to={"/kontakt"} className={"d-flex  justify-content-end align-items-center text-white text-decoration-none mt-2"}>
                      Kontakt
                      <ChevronRightSVG className={"svgFill ms-1"} />
                    </Link>
                  </li>
                </ul>
              </div>

            </div>
          </div>

        </div>

  </div>

  </>;

};

export default Layout;
