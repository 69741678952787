// extracted by mini-css-extract-plugin
export var layoutWrapper = "layout-module--layoutWrapper--3N4H7";
export var headerWrapper = "layout-module--headerWrapper--GA_9p";
export var header = "layout-module--header--1oO9S";
export var children = "layout-module--children--1xcUm";
export var footer = "layout-module--footer--2pheg";
export var logo = "layout-module--logo--3k-i6";
export var logoFooter = "layout-module--logoFooter--343I8";
export var bars = "layout-module--bars--3GyMg";
export var mobileOverlay = "layout-module--mobileOverlay--qUbl6";
export var mobileOverlayClose = "layout-module--mobileOverlayClose--3uJmP";
export var wordmark = "layout-module--wordmark--3pqg1";